import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Layout from "../components/Layout";

const StyledWrapper = styled.section`
  padding: 4rem 0;

  ol {
    padding-left: 4em;
  }

  li {
    list-style-type: lower-roman;
    padding-left: 1em;
  }

  p {
    margin-bottom: 1.5em;
  }

  .row {
    margin-bottom: 4rem;
  }

  h4 {
    margin-bottom: 1em;
  }

  .lvl2 {
    display: grid;
    grid-template-columns: 2em auto;
  }

  .lvl2x {
    display: grid;
    grid-template-columns: 3em auto;
  }

  .lvl2x + ol {
    padding-left: 5em;
  }

  a {
    color: var(--blueDress);
    &:hover {
      color: var(--blueDress);
      text-decoration: underline;
    }
  }
`;
const TermsOfUse = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Use | Niro Capital Private Limited</title>
      </Helmet>
      <Layout>
        <StyledWrapper>
          <div className="container">
            <div className="row mb-5">
              <p className="d-flex justify-content-end">
                Last updated on 06th January, 2022
              </p>
              <h1 className="text-center">Terms of Use</h1>
            </div>
            <div className="row">
              <p>
                These terms of use (“<strong>Terms</strong>”) mandate the terms
                on which the users (“<strong>You</strong>” or “
                <strong>Your</strong>” or “<strong>Yourself</strong>” or “
                <strong>User</strong>”) access and register on the website
                www.nirocapital.com and mobile application ‘the{" "}
                <strong>Niro Direct</strong>’ operated and managed by{" "}
                <strong>Niro Capital Private Limited (“Company”)</strong>,
                collectively referred to as (“<strong>the Platform</strong>”).
              </p>
              <p>
                This Terms is an electronic record in terms of the Information
                Technology Act, 2000 and rules made thereunder as applicable.
                The Terms is (i) published in accordance with the provisions of
                Rule 3(1)(a) of the Information Technology (Intermediary
                Guidelines and Digital Media Ethics Code) Rules, 2021 and (ii)
                generated by a computer system and does not require any
                physical, electronic, or digital signatures.
              </p>
              <p>
                Please read the Terms and Privacy Policy carefully before using
                or registering on the Platform or accessing any material,
                information through the Platform. By clicking on the “I agree”
                button, You accept these Terms and Privacy Policy and agree to
                be legally bound by the same.
              </p>
              <p>
                The Company retains an unconditional right to modify or amend
                this Terms and will notify You of the same. You can determine
                when this Terms was last modified by referring to the “Last
                Updated” legend above. It shall be Your responsibility to check
                this Terms periodically for changes. Your acceptance of the
                amended Terms shall signify Your consent to such changes and
                agreement to be legally bound by the same. Further, by
                furnishing Your Personal Information to the Company or giving
                Your consent to the Company to access Your Personal Information
                for the purpose of rendering the Services (as defined below),
                You also agree that You are interested in availing the Services
                through the Platform in accordance with these Terms.
              </p>
            </div>

            <div className="row">
              <h4>1. Services</h4>
              <div className="lvl2">
                <p>1.1</p>
                <p>
                  You acknowledge that the Platform allows You to avail the
                  following services including:{" "}
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    limited, non – exclusive, non – transferable, royalty free
                    licence to use the Platform for the purposes of registering
                    on the Platform, accessing the Content (as defined below)
                    and availing the services through the Platform including
                    access to loans and financial assistance made available
                    through the Platform by partnered financial institutions;
                  </p>
                </li>
                <li>
                  <p>
                    Assistance in completing Know Your Customer verification
                    process, for the partnered financial institutions and
                    facilitating the execution of loan documents;{" "}
                  </p>
                </li>
                <li>
                  <p>
                    Periodic notifications and transaction updates and
                    summaries, reminders on SMS, email, calls and WhatsApp;
                  </p>
                </li>
                <li>
                  <p>
                    Maintaining a record of Your financial transactions,
                    repayment schedule and credit history undertaken through the
                    Platform, in a consolidated, secure, and confidential
                    manner; and
                  </p>
                </li>
                <li>
                  <p>
                    Promptly and efficiently responding to Your queries or
                    issues relating to Your Account.
                  </p>
                </li>
              </ol>

              <p>
                The aforementioned services shall be collectively referred to as
                the “Services”. The Company may, at its discretion, add, modify,
                or remove any of the Services listed above from time to time.
              </p>

              <div className="lvl2">
                <p>1.2</p>
                <p>
                  As part of the Services provided to You, You agree to provide
                  honest feedback/review about Our Services, if required by the
                  Company.
                </p>
              </div>
              <div className="lvl2">
                <p>1.3</p>
                <p>
                  The Company does not: (i) adopt any ‘unfair trade practices’
                  either on the Platform or otherwise with respect to the
                  Services; and (ii) discriminate between Users of the same
                  class or makes any arbitrarily classification of the Users.
                </p>
              </div>
            </div>
            <div className="row">
              <h4>2. Eligibility to Use</h4>
              <div className="lvl2">
                <p>2.1</p>
                <p>
                  The Services are not available to minors i.e., persons under
                  the age of 18 (eighteen) years or to any Users suspended or
                  removed by the Company for any reason whatsoever. You hereby
                  represent that You are of legal age to form a binding
                  contract, are not a person barred from receiving Services from
                  the Platform under the applicable laws and are competent to
                  enter into a binding contract.
                </p>
              </div>
              <div className="lvl2">
                <p>2.2</p>
                <p>
                  We maintain the right to provide Services to only those Users
                  who are – a) competent to enter into legally binding
                  contracts, b) have made the representations as provided above,
                  c) and also qualify under the internal policy(ies) for the
                  same determined solely by Us from time to time. We shall have
                  the sole right to change, modify, add, or remove, in whole or
                  in part, internal policy(ies), in relation to the provision of
                  the Services at any time by providing a prior written notice
                  or intimation to the Users.
                </p>
              </div>
              <div className="lvl2">
                <p>2.3</p>
                <p>
                  You shall not have more than one active Account (as defined
                  below) on the Platform. Additionally, You are prohibited from
                  selling, trading, or otherwise transferring Your Account to
                  another party or impersonating any other person for the
                  purposing of creating an Account with the Platform.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>3. User Account, Password, and Security</h4>
              <div className="lvl2">
                <p>3.1</p>
                <p>
                  In order to access the Services on the Platform, You will have
                  to register on the Platform by providing details as may be
                  required by the Company and provided for in the Privacy Policy
                  (“<strong>Account</strong>”).
                </p>
              </div>
              <div className="lvl2">
                <p>3.2</p>
                <p>
                  You shall ensure and confirm that the Account information
                  provided by You is complete, accurate and up-to-date. If there
                  is any change in the Account information, You shall promptly
                  update Your Account information on the Platform. If You
                  provide any information that is untrue, inaccurate, not
                  current or incomplete (or becomes untrue, inaccurate, not
                  current or incomplete), or if the Company has reasonable
                  grounds to suspect that such information is untrue,
                  inaccurate, not current or incomplete, the Company has the
                  right to suspend or terminate Your Account and refuse any and
                  all current or future use of the Platform (or any portion
                  thereof) at its discretion, in addition to any right that the
                  Company may have against You at law or in equity, for any
                  misrepresentation of information provided by You.
                </p>
              </div>
              <div className="lvl2">
                <p>3.3</p>
                <p>
                  You will be responsible for maintaining the confidentiality of
                  the Account information and are fully responsible for all
                  activities that occur under Your Account. You agree to (a)
                  immediately notify the Company of any unauthorized use of Your
                  Account information or any other breach of security, and (b)
                  ensure that You exit from Your Account at the end of each
                  session. The Company cannot and will not be liable for any
                  loss or damage arising from Your failure to comply with this
                  provision. You may be held liable for losses incurred by the
                  Company or any other user of or visitor to the Platform due to
                  authorized or unauthorized use of Your Account as a result of
                  Your failure in keeping Your Account information secure and
                  confidential.
                </p>
              </div>
              <div className="lvl2">
                <p>3.4</p>
                <p>
                  You hereby acknowledge and agree that the deletion of the
                  Platform from the Your device does not constitute termination
                  of Your Account and agree to undertake the process detailed
                  herein in order to complete the de-registration of Your
                  Account. If and when You are desirous of having Your name and
                  other details removed from Our records, immediately upon
                  receiving Your written request to that effect, We shall remove
                  and/delete all such information. However, We may retain
                  certain information regarding Your Account, even after
                  receiving a deletion request from You, where such retention is
                  required under applicable laws. You hereby acknowledge that
                  the removal of Your details from the Platform does not
                  constitute termination of Your outstanding obligations, if
                  any, to the partnered financial institution from whom You may
                  have availed credit through the Platform.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>4. Use of Platform</h4>
              <div className="lvl2">
                <p>4.1</p>
                <p>
                  Subject to compliance with the Terms, the Company hereby
                  grants You a non-exclusive, limited privilege to access and
                  use the Platform. You agree to use the Platform only: (a) for
                  purposes that are permitted by the Terms; and (b) in
                  accordance with any applicable law, regulation or generally
                  accepted practices or guidelines. You agree not to engage in
                  activities that may adversely affect the use of the Platform
                  by the Company or other Users.
                </p>
              </div>
              <div className="lvl2">
                <p>4.2</p>
                <p>
                  You agree not to access (or attempt to access) the Platform by
                  any means other than through the interface that is provided by
                  the Company. You shall not use any deep-link, robot, spider or
                  other automatic device, program, algorithm or methodology, or
                  any similar or equivalent manual process, to access, acquire,
                  copy or monitor any portion of the Platform or Content (as
                  defined below), or in any way reproduce or circumvent the
                  navigational structure or presentation of the Platform,
                  materials or any Content, to obtain or attempt to obtain any
                  materials, documents or information through any means not
                  specifically made available through the Platform.
                </p>
              </div>
              <div className="lvl2">
                <p>4.3</p>
                <p>
                  You acknowledge and agree that by accessing or using the
                  Platform, You may be exposed to content from others that You
                  may consider offensive, indecent, or otherwise objectionable.
                  The Company disclaims all liabilities arising in relation to
                  such offensive content on the Platform.
                </p>
              </div>
              <div className="lvl2">
                <p>4.4</p>
                <p>
                  Further, You undertake not to host, display, upload, modify,
                  publish, transmit, store, update or share any information
                  that:
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    belongs to another person and to which the User does not
                    have any right;
                  </p>
                </li>

                <li>
                  <p>
                    is defamatory, obscene, pornographic, paedophilic, invasive
                    of another's privacy, including bodily privacy, insulting,
                    or harassing on the basis of gender, libellous, racially, or
                    ethnically objectionable, relating or encouraging money
                    laundering or gambling, or otherwise inconsistent with or
                    contrary to the laws in force;
                  </p>
                </li>

                <li>
                  <p>is harmful to child;</p>
                </li>

                <li>
                  <p>
                    infringes any patent, trademark, copyright, or other
                    proprietary rights;
                  </p>
                </li>

                <li>
                  <p>violates any law for the time being in force;</p>
                </li>

                <li>
                  <p>
                    deceives or misleads the addressee about the origin of the
                    message or knowingly and intentionally communicates any
                    information which is patently false or misleading in nature
                    but may reasonably be perceived as a fact;
                  </p>
                </li>

                <li>
                  <p>impersonates another person;</p>
                </li>

                <li>
                  <p>
                    threatens the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign
                    States, or public order, or causes incitement to the
                    commission of any cognisable offence or prevents
                    investigation of any offence or is insulting other nation;
                  </p>
                </li>

                <li>
                  <p>
                    contains software virus or any other computer code, file or
                    program designed to interrupt, destroy, or limit the
                    functionality of any computer resource;
                  </p>
                </li>

                <li>
                  <p>
                    is patently false and untrue, and is written or published in
                    any form, with the intent to mislead or harass a person,
                    entity, or agency for financial gain or to cause any injury
                    to any person;
                  </p>
                </li>

                <li>
                  <p>
                    disrupt or interfere with the security of, or otherwise
                    cause harm to, the Platform, systems resources, accounts,
                    passwords, servers, or networks connected to or accessible
                    through the Platform or any affiliated or linked sites;
                  </p>
                </li>

                <li>
                  <p>
                    violate the Terms contained herein or elsewhere and/or the
                    Privacy Policy; and
                  </p>
                </li>

                <li>
                  <p>
                    reverse engineer, modify, copy, distribute, transmit,
                    display, perform, reproduce, publish, license, create
                    derivative works from, transfer, or sell any information or
                    software obtained from the Platform.
                  </p>
                </li>
              </ol>
              <div className="lvl2">
                <p>4.5</p>
                <p>
                  Except as expressly indicated herein, the Company hereby
                  grants You a non-exclusive, freely revocable (upon notice from
                  the Company), non-transferable access to view any Content
                  available on the Platform, subject to the following
                  conditions:
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    You may access the Content solely for personal or
                    informational purposes, in accordance with the Terms;
                  </p>
                </li>

                <li>
                  <p>
                    You may not modify or alter the Content available on the
                    Platform;
                  </p>
                </li>

                <li>
                  <p>
                    You may not distribute or sell, rent, lease, license or
                    otherwise make any Content on the Platform available to
                    others; and
                  </p>
                </li>

                <li>
                  <p>
                    You may not remove any text, copyright or other proprietary
                    notices contained in the Content downloaded from the
                    Platform.
                  </p>
                </li>
              </ol>
            </div>

            <div className="row">
              <h4>5. Intellectual Property Rights </h4>
              <div className="lvl2">
                <p>5.1</p>
                <p>
                  The Platform and the processes, and their selection and
                  arrangement, including but not limited to, all text, reports
                  generated, videos, graphics, user interfaces, visual
                  interfaces, sounds and music (if any), artwork and computer
                  code (and any combinations thereof) (collectively, the “
                  <strong>Content</strong>”) on the Platform is owned and
                  controlled by the Company and the design, structure,
                  selection, coordination, expression, look and feel and
                  arrangement of such Content is protected by copyright, patent
                  and trademark laws, and various other intellectual property
                  rights. You are not entitled to duplicate, distribute, create
                  derivative works of, display, or commercially exploit the
                  Content, features or facilities, directly or indirectly,
                  without Our prior written permission. If You would like to
                  request permission in respect of any particular Content, You
                  can contact Us in the manner provided for herein.
                </p>
              </div>
              <div className="lvl2">
                <p>5.2</p>
                <p>
                  The trademarks, logos and service marks displayed on the
                  Platform (“Marks”) are the property of the Company or other
                  respective third parties, as the case may be. You are not
                  permitted to use the Marks without the prior consent of the
                  Company or the third party that may own the Marks.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>6. Disclaimer of Warranties & Liability</h4>
              <div className="lvl2">
                <p>6.1</p>
                <p>
                  You expressly understand and agree that, to the maximum extent
                  permitted by applicable law:
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    the Platform and other Content are provided by the Company
                    on an “as is” basis without warranty of any kind, express,
                    implied, statutory, or otherwise, including the implied
                    warranties of title, non-infringement, merchantability, or
                    fitness for a particular purpose. Without limiting the
                    foregoing, the Company makes no warranty that (i) the
                    Platform or Services will meet Your requirements or Your use
                    of the Platform will be uninterrupted, timely, secure or
                    error-free; (ii) the results that may be obtained from the
                    use of the Platform will be effective, accurate or reliable;
                    and (iii) any errors or defects in the Platform will be
                    corrected. No advice or information, whether oral or
                    written, obtained by You from the Company shall create any
                    warranty not expressly stated in the Terms.
                  </p>
                </li>

                <li>
                  <p>
                    Company will have no liability related to any user Content
                    arising under intellectual property rights, libel, privacy,
                    publicity, obscenity, or other laws. Company also disclaims
                    all liability with respect to the misuse, loss,
                    modification, or unavailability of any user Content.
                  </p>
                </li>

                <li>
                  <p>
                    Company will not be liable for any loss that You may incur
                    as a consequence of unauthorized use of Your Account or
                    Account information in connection with the Platform either
                    with or without your knowledge.
                  </p>
                </li>

                <li>
                  <p>
                    Company has endeavoured to ensure that all the information
                    on the Platform is correct, but the Company neither warrants
                    nor makes any representations regarding the quality,
                    accuracy or completeness of any data, information regarding
                    the Services or otherwise. The Company shall not be
                    responsible for the delay or inability to use the Platform
                    or related functionalities, the provision of or failure to
                    provide functionalities, or for any information, software,
                    functionalities and related graphics obtained through the
                    Platform, or otherwise arising out of the use of the
                    Platform, whether based on contract, tort, negligence,
                    strict liability or otherwise. Further, the Company shall
                    not be held responsible for non-availability of the Platform
                    during periodic maintenance operations or any unplanned
                    suspension of access to the Platform that may occur due to
                    technical reasons or for any reason beyond the Company’s
                    control. You understand and agree that any material or data
                    downloaded or otherwise obtained through the Platform is
                    done entirely at Your own discretion and risk, and that You
                    will be solely responsible for any damage to Your computer
                    systems or loss of data that results from the download of
                    such material or data.{" "}
                  </p>
                </li>

                <li>
                  <p>
                    These Terms are intended for Users within the territory of
                    India and govern Your rights as per applicable law within
                    the territory of India. However, in the event You fall under
                    a jurisdiction outside the purview of Indian law, We will
                    not be liable for any claim, action and/or right
                    initiated/exercised by You as per the extant laws of that
                    jurisdiction. Therefore, We request You to kindly use the
                    Platform accordingly.
                  </p>
                </li>

                <li>
                  <p>
                    The partnered financial institution is responsible for the
                    decision on the loan and financial assistance, i.e., for
                    approval or rejection of Your application. The Company is
                    not liable or responsible in any manner for any loss or
                    damage caused to You in relation to such decision of the
                    respective partnered institution.
                  </p>
                </li>
              </ol>
            </div>

            <div className="row">
              <h4>7. Indemnification and Limitation of Liability</h4>
              <div className="lvl2">
                <p>7.1</p>
                <p>
                  You agree to indemnify, defend and hold harmless the Company
                  and its affiliates including but not limited to its (and its
                  affiliates’) officers, directors, consultants, agents and
                  employees (“<strong>Indemnitees</strong>”) from and against
                  any and all losses, liabilities, claims, damages, demands,
                  costs and expenses (including legal fees and disbursements in
                  connection therewith and interest chargeable thereon) asserted
                  against or incurred by the Indemnitees that arise out of,
                  result from, or may be payable by virtue of, any breach or
                  non-performance of any representation, warranty, covenant or
                  agreement made or obligation to be performed by You pursuant
                  to these Terms and/or the Privacy Policy. Further, You agree
                  to hold the Indemnitees harmless against any claims made by
                  any third party due to, or arising out of, or in connection
                  with, Your use of the Platform, any misrepresentation with
                  respect to the data or information provided by You, Your
                  violation of the Terms, and/or the Privacy Policy, Your
                  violation of applicable laws, or Your violation of any rights
                  of another, including any intellectual property rights.
                </p>
              </div>
              <div className="lvl2">
                <p>7.2</p>
                <p>
                  In no event shall the Company and its officers, partners,
                  consultants, agents and employees, be liable to You or any
                  third party for any special, incidental, indirect,
                  consequential or punitive damages whatsoever, arising out of
                  or in connection with Your use of or access to the Platform or
                  Content on the Platform.
                </p>
              </div>
              <div className="lvl2">
                <p>7.3</p>
                <p>
                  The limitations and exclusions in this Section apply to the
                  maximum extent permitted by applicable laws.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>8. Violation of the Terms </h4>
              <div className="lvl2">
                <p>8.1</p>
                <p>
                  You agree that any violation by You of these Terms will
                  constitute an unlawful and unfair business practice, and will
                  cause irreparable harm to the Company, for which monetary
                  damages would be inadequate, and You consent to the Company
                  obtaining any injunctive or equitable relief that they deem
                  necessary or appropriate in such circumstances. These remedies
                  are in addition to any other remedies that the Company may
                  have at law or in equity.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>9. Suspension and Termination </h4>
              <div className="lvl2">
                <p>9.1</p>
                <p>
                  The Terms will continue to apply until terminated by either
                  You or the Company as set forth below. If You object to the
                  Terms or are dissatisfied with the Platform, your only
                  recourse is to (i) close Your Account on the Platform; and/or
                  (ii) stop accessing the Platform.
                </p>
              </div>
              <div className="lvl2">
                <p>9.2</p>
                <p>
                  The Company may delist You or block Your future access to the
                  Platform or suspend or terminate Your Account if it believes,
                  in its sole and absolute discretion that You have infringed,
                  breached, violated, abused, or unethically manipulated or
                  exploited any term of these Terms or the Privacy Policy or
                  anyway otherwise acted unethically. Notwithstanding anything
                  in this clause, these Terms will survive indefinitely unless
                  and until the Company chooses to terminate them.
                </p>
              </div>
              <div className="lvl2">
                <p>9.3</p>
                <p>
                  If You or the Company terminate Your use of the Platform, the
                  Company may delete any Content or other materials relating to
                  You and the Company shall have no liability to You or any
                  third party for doing so. However, Your transactions details
                  may be preserved by the Company for purposes of tax or
                  regulatory compliance.
                </p>
              </div>
              <div className="lvl2">
                <p>9.4</p>
                <p>
                  You shall be liable to pay any fees or charges as may be
                  applicable in respect of the Services until the date of
                  termination by either party whatsoever.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>10. Governing Law and Jurisdiction </h4>
              <div className="lvl2x">
                <p>10.1</p>
                <p>
                  These Terms shall be governed by and constructed in accordance
                  with the laws of India without reference to conflict of laws
                  principles and disputes arising in relation hereto shall be
                  subject to the exclusive jurisdiction of courts at Bangalore,
                  Karnataka, India.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>11. Report Abuse</h4>
              <div className="lvl2x">
                <p>11.1</p>
                <p>
                  In the event You come across any abuse or violation of these
                  Terms or if You become aware of any objectionable content on
                  the Platform, please report the same to the following e-mail
                  id: <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>
                  .
                </p>
              </div>
            </div>

            <div className="row">
              <h4>12. Communications</h4>
              <div className="lvl2x">
                <p>12.1</p>
                <p>
                  You hereby expressly agree to receive communications by way of
                  calls (
                  <em>
                    including using pre-recorded messages or artificial voice
                  </em>
                  ), SMS, emails and/or WhatsApp from the Company and other
                  third parties duly authorized by the Company. You certify,
                  warrant and represent that the telephone numbers and/or email
                  addresses and any other information that You have provided to
                  Us are Your own and are true, accurate, current and complete.
                  You agree to notify Us whenever You stop using a particular
                  telephone number(s) and/or email address(es). You can
                  unsubscribe or opt-out from receiving communications through
                  calls, SMS, e-mail, and WhatsApp anytime by writing to Us at{" "}
                  <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>. In
                  which case, the Company will only send You communications
                  solely required for the purposes of the Services provided
                  through the Platform.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>13. Grievance Redressal Mechanism</h4>
              <div className="lvl2x">
                <p>13.1</p>
                <p>
                  <strong>Grievance Handling:</strong> If You have any
                  grievances, or complaints or concerns with respect to the
                  Platform, the Content, or the Services, You can contact the
                  designated Grievance Officer of the Company, namely, Sankalp
                  Mathur, at{" "}
                  <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>.
                </p>
              </div>
              <div className="lvl2x">
                <p>13.2</p>
                <p>
                  By lodging a complaint or grievance, You agree to provide
                  complete support to the Grievance Officer and such reasonable
                  information as may be sought by them from You.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>14. General Provisions</h4>
              <div className="lvl2x">
                <p>14.1</p>
                <p>
                  <strong>Notice:</strong> All notices from the Company will be
                  served by email to Your registered email address or by general
                  notification on the Platform.
                </p>
              </div>
              <div className="lvl2x">
                <p>14.2</p>
                <p>
                  <strong>Assignment:</strong> You cannot assign or otherwise
                  transfer the Terms, or any rights granted hereunder to any
                  third party. The Company’s rights under the Terms are freely
                  transferable by the Company to any third party without the
                  requirement of seeking Your consent.
                </p>
              </div>
              <div className="lvl2x">
                <p>14.3</p>
                <p>
                  <strong>Severability:</strong> If, for any reason, a court of
                  competent jurisdiction finds any provision of the Terms, or
                  any portion thereof, to be unenforceable, that provision shall
                  be enforced to the maximum extent permissible so as to give
                  effect to the intent of the parties as reflected by that
                  provision, and the remainder of the Terms shall continue in
                  full force and effect.
                </p>
              </div>
              <div className="lvl2x">
                <p>14.4</p>
                <p>
                  <strong>Waiver:</strong> Any failure by Company to enforce or
                  exercise any provision of the Terms, or any related right,
                  shall not constitute a waiver by Company of that provision or
                  right.
                </p>
              </div>
            </div>

            <div className="row">
              <h4>15. IP Infringement</h4>
              <div className="lvl2x">
                <p>15.1</p>
                <p>
                  If You believe the Platform violates Your intellectual
                  property, You must promptly notify Company in writing at{" "}
                  <a href="mailto:sankalp@niro.co.in">sankalp@niro.co.in</a>.
                  These notifications should only be submitted by the owner of
                  the intellectual property or an agent authorized to act on
                  his/her behalf. However, any false claim by You may result in
                  the termination of Your access to the Platform. You are
                  required to provide the following details in Your notice:
                </p>
              </div>
              <ol>
                <li>
                  <p>
                    the intellectual property that You believe is being
                    infringed;
                  </p>
                </li>

                <li>
                  <p>
                    the item that You think is infringing and include sufficient
                    information about where the material is located on the
                    Platform;
                  </p>
                </li>

                <li>
                  <p>
                    a statement that You believe in good faith that the item You
                    have identified as infringing is not authorized by the
                    intellectual property owner, its agent, or the law to be
                    used in connection with the Platform;
                  </p>
                </li>

                <li>
                  <p>
                    Your contact details, such as Your address, telephone
                    number, and/or email;{" "}
                  </p>
                </li>

                <li>
                  <p>
                    a statement that the information You provided in Your notice
                    is accurate, and that You are the intellectual property
                    owner or an agent authorized to act on behalf of the
                    intellectual property owner whose intellectual property is
                    being infringed; and{" "}
                  </p>
                </li>

                <li>
                  <p>Your physical or electronic signature. </p>
                </li>
              </ol>
            </div>

            <div className="row">
              <h4>
                <em>Copyright © All rights reserved.</em>
              </h4>
            </div>
          </div>
        </StyledWrapper>
      </Layout>
    </>
  );
};

export default TermsOfUse;
